import { NewSpace } from "./components/NewSpace";
import { LandingOptions } from "./components/LandingOptions";
import { Layout } from "./components/layout/Layout";
import { JoinSpace } from "./components/JoinSpace";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingOptions />} />
        <Route path="/space">
          <Route path="new/" element={<NewSpace />} />
          <Route path="join/:spaceId" element={<JoinSpace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
