export const FooterLayout = () => {
  return (
    <footer className="mt-auto bg-purple-700 text-center text-gray-200">
      <p>
        Copyright © 2023 - All right reserved by{" "}
        <span className="underline">Spell It</span>
      </p>
    </footer>
  );
};
