import ReactGA from "react-ga4";
import { GAEventName } from "../types/constant";

export const createSpaceClickedEvent = () =>
  ReactGA.event(GAEventName.CreateSpaceEvent, {});

export const joinSpaceClickedEvent = () =>
  ReactGA.event(GAEventName.JoinSpaceEvent, {});

export const fatalErrorEvent = (page = "NO_PAGE", errorDetails) =>
  ReactGA.event(`error-${page}`, errorDetails);

export const addDataClickedEvent = (dataDetails) =>
  ReactGA.event(GAEventName.AddDataEvent, dataDetails);

export const apiEvent = (apiName, eventDetails) =>
  ReactGA.event(apiName, eventDetails);
