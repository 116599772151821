import { createClient } from "@supabase/supabase-js";
import { useAtom } from "jotai";
import { useState } from "react";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router-dom";
import { getSpaceData } from "../api/space-api";
import { globalErrorStore, listOfInputValuesStore } from "../store/store";
import { TableView } from "./TableView";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON;

const supabase = createClient(supabaseUrl, supabaseKey);

export const JoinSpace = () => {
  const { spaceId } = useParams();
  const [, setListOfInputValues] = useAtom(listOfInputValuesStore);
  const [isUserIdle, setUserIdle] = useState(false);
  const [, setGlobalErrorState] = useAtom(globalErrorStore);
  const navigate = useNavigate();

  const subscribeToTable = (spaceId) => {
    return supabase
      .from(`space_data:space_id=eq.${spaceId}`)
      .on("UPDATE", (payload) => {
        setListOfInputValues(payload.new.data);
      })
      .subscribe();
  };

  const onIdle = () => {
    const subscriptions = supabase.getSubscriptions();
    subscriptions.forEach((subscription) => subscription.unsubscribe());
    setUserIdle(true);
  };

  const handleUserIdleOnClick = (event) => {
    event.preventDefault();
    fetchDataBySpaceId(spaceId);
    subscribeToTable(spaceId);
    setUserIdle(false);
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 2,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const fetchDataBySpaceId = async (spaceId) => {
    //fetch the data
    const getSpaceResponse = await getSpaceData(spaceId);
    if (getSpaceResponse.ok) {
      setListOfInputValues(getSpaceResponse.data.spaceData);
      subscribeToTable(spaceId);
    } else {
      setGlobalErrorState({
        error: true,
        errorTitle: "Join failed",
        errorType: "failure",
        errorMessage: "Sorry, Invalid or space doesn't exist",
      });
      navigate("/");
    }
  };

  useEffect(() => {
    fetchDataBySpaceId(spaceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center gap-5 flex-col pb-6 w-full">
      {isUserIdle && (
        <div>
          <a
            className="underline text-purple-700 font-semibold"
            href="/"
            onClick={handleUserIdleOnClick}
          >
            Refresh
          </a>{" "}
          the space to update, since you are idle
        </div>
      )}
      <div>
        <TableView mode="shared" handleDeleteClick={null} />
      </div>
    </div>
  );
};
