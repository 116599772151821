import { Button, Label, Spinner, TextInput } from "flowbite-react";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { getSpaceData, updateSpaceData } from "../api/space-api";
import {
  globalErrorStore,
  listOfInputValuesStore,
  spaceInformationStore,
} from "../store/store";
import { addDataClickedEvent } from "../util/gaEvents";
import { helperTextForSpaceId } from "../util/util";
import { TableView } from "./TableView";

export const NewSpace = () => {
  const fieldKeyRef = useRef(null);
  const fieldValueRef = useRef(null);

  const recycleTime = 15;

  const spaceId = window.sessionStorage.getItem("spaceId");
  const spaceCreatedAt = window.sessionStorage.getItem("spaceCreatedAt");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [spaceInformation, setSpaceInformation] = useAtom(
    spaceInformationStore
  );
  const [listOfInputValues, setListOfInputValues] = useAtom(
    listOfInputValuesStore
  );
  const [, setGlobalErrorState] = useAtom(globalErrorStore);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [errorState, setErrorState] = useState({
    isErrored: false,
    errorMessage: "",
  });

  const [helperText, setHelperText] = useState("");

  const [fieldState, setFieldState] = useState({
    fieldKey: "",
    fieldValue: "",
  });

  const handleFieldChange = (event) => {
    const nameOfInput = event.target.name;
    setFieldState({ ...fieldState, [nameOfInput]: event.target.value });
  };

  const handleSpaceRecycle = () => {
    setGlobalErrorState({
      error: true,
      errorTitle: "Info",
      errorType: "info",
      errorMessage:
        "Space is recycled. Please create new space to share information.",
    });
    window.sessionStorage.removeItem("spaceId");
    window.sessionStorage.removeItem("spaceCreatedAt");
    navigate("/");
  };

  const handleAddClick = async () => {
    addDataClickedEvent({ fieldKey: fieldState.fieldKey });
    const response = await updateSpaceData(spaceInformation.space_id, [
      ...listOfInputValues,
      fieldState,
    ]);
    if (response.ok) {
      setErrorState({
        isErrored: false,
        errorMessage: "",
      });
      setListOfInputValues([...listOfInputValues, fieldState]);
      setFieldState({
        fieldKey: "",
        fieldValue: "",
      });
      fieldKeyRef.current.focus();
    } else {
      setErrorState({
        isErrored: true,
        errorMessage: "Sorry, failed to update. Try again!",
      });
    }
  };

  const handleDeleteClick = async (indexToDelete) => {
    const updatedState = listOfInputValues.filter(
      (inputValue, index) => index !== indexToDelete
    );

    const response = await updateSpaceData(
      spaceInformation.space_id,
      updatedState
    );
    if (response.ok) {
      setErrorState({
        isErrored: false,
        errorMessage: "",
      });
      setListOfInputValues(updatedState);
      setFieldState({
        fieldKey: "",
        fieldValue: "",
      });
    } else {
      setErrorState({
        isErrored: true,
        errorMessage: "Sorry, failed to delete. Try again!",
      });
    }
  };

  const checkSpaceRecycled = () => {
    return (Date.now() - Number(spaceCreatedAt)) / (1000 * 60) > recycleTime;
  };

  useEffect(() => {
    if (fieldState.fieldKey && fieldState.fieldValue) {
      setIsAddButtonDisabled(false);
    } else {
      setIsAddButtonDisabled(true);
    }
  }, [fieldState]);

  useEffect(() => {
    const fetchSpaceData = async () => {
      setLoading(true);
      //fetch the data
      const getSpaceResponse = await getSpaceData(spaceId);
      if (getSpaceResponse.ok) {
        setListOfInputValues(getSpaceResponse.data.spaceData);
        setSpaceInformation(getSpaceResponse.data.spaceInformation);
        setLoading(false);
      } else {
        setGlobalErrorState({
          error: true,
          errorTitle: "Create Failed:",
          errorType: "failure",
          errorMessage: "Sorry, Invalid or space doesn't exist",
        });
        navigate("/");
      }
    };
    if (spaceId !== undefined) {
      fetchSpaceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (checkSpaceRecycled()) {
      handleSpaceRecycle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceCreatedAt]);

  useEffect(() => {
    const whileMounting = async () => {
      //If spaceId doesn't exist in the session, then route it back to home page with error
      if (!spaceId) {
        setLoading(true);
        //fetch the data
        const getSpaceResponse = await getSpaceData(spaceId);
        if (getSpaceResponse.ok) {
          setListOfInputValues(getSpaceResponse.data.spaceData);
          setSpaceInformation(getSpaceResponse.data.spaceInformation);
          setLoading(false);
        } else {
          setGlobalErrorState({
            error: true,
            errorTitle: "Create Failed:",
            errorType: "failure",
            errorMessage: "Sorry, Invalid or space doesn't exist",
          });
          navigate("/");
        }
      }
    };

    whileMounting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (spaceInformation && Object.keys(spaceInformation).length) {
      setHelperText(helperTextForSpaceId(spaceInformation.space_display_id));
    }
  }, [spaceInformation]);

  const countDownRender = ({ hours, minutes, seconds, completed }) => {
    if (completed && checkSpaceRecycled()) {
      handleSpaceRecycle();
    } else {
      return (
        <span className="text-xs text-center">
          This space will be recycled in{" "}
          <span className="text-purple-800 font-semibold">
            {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
            {String(seconds).padStart(2, "0")}
          </span>
        </span>
      );
    }
  };

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <Spinner color="purple" size="xl"></Spinner>
        </div>
      ) : (
        <div className="flex items-center justify-center gap-5 flex-col">
          <div>
            <div className="flex flex-row gap-2 justify-center items-center">
              <p className="text-lg text-center">Space identifier </p>
              <span className="bg-purple-300 rounded-lg">
                <span className="text-purple-800 text-lg m-2 font-semibold tracking-widest">
                  {spaceInformation.space_display_id}
                </span>
              </span>
            </div>
            <p className="text-xs text-center">{helperText}</p>
          </div>
          {errorState.isErrored && (
            <div className="flex justify-between text-black shadow-inner rounded-lg pt-2 pb-2 pr-5 pl-5 bg-red-400">
              <p className="self-center">{errorState.errorMessage}</p>
            </div>
          )}
          <div className="flex gap-3 md:w-1/2 md:flex-row flex-col w-3/4">
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="fieldKey" value="Information Type" />
              </div>
              <TextInput
                className="w-full"
                value={fieldState.fieldKey}
                id="fieldKey"
                name="fieldKey"
                onChange={handleFieldChange}
                ref={fieldKeyRef}
                placeholder="Type here"
                list="field-type-options"
                required={true}
              />
              <datalist id="field-type-options">
                <option value="First Name" />
                <option value="Last Name" />
                <option value="Full Name" />
                <option value="Address" />
                <option value="Zip Code" />
                <option value="Home Phone" />
                <option value="Cell Phone" />
              </datalist>
            </div>
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="fieldValue" value="Value" />
              </div>
              <TextInput
                type="text"
                id="fieldValue"
                name="fieldValue"
                onChange={handleFieldChange}
                value={fieldState.fieldValue}
                ref={fieldValueRef}
                placeholder="Type here"
                required={true}
              />
            </div>
          </div>
          <div className="">
            <Button onClick={handleAddClick} disabled={isAddButtonDisabled}>
              Add to Space
            </Button>
          </div>
          <Countdown
            date={Number(spaceCreatedAt) + 1000 * 60 * recycleTime}
            renderer={countDownRender}
          />
          <div className="divider"></div>
          {listOfInputValues.length !== 0 && (
            <>
              <div className="pb-12">
                <TableView
                  mode="delete"
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
