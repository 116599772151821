import { atom } from "jotai";

export const spaceInformationStore = atom({});
export const listOfInputValuesStore = atom([]);
export const globalErrorStore = atom({
  error: false,
  errorType: "",
  errorTitle: "",
  errorMessage: "",
});
