import { apiEvent } from "../util/gaEvents";

const CREATE_SPACE_API_URL = "/api/create-space";
const UPDATE_SPACE_API_URL = "/api/update-space";
const GET_SPACE_API_URL = "/api/get-space";
const JOIN_SPACE_API_URL = "/api/join-space";

export const createNewSpace = async () => {
  return fetch(CREATE_SPACE_API_URL)
    .then((response) => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        apiEvent("create-api", { response: "success" });
        return {
          apiStatus: true,
          spaceInformation: data,
        };
      } else {
        apiEvent("create-api", { response: "failed" });
        return {
          apiStatus: false,
        };
      }
    })
    .catch((error) => {
      apiEvent("create-api", { response: "error" });
      return {
        apiStatus: false,
      };
    });
};

export const updateSpaceData = async (spaceId, data) => {
  return fetch(UPDATE_SPACE_API_URL, {
    method: "post",
    body: JSON.stringify({
      spaceId,
      data,
    }),
  })
    .then((response) => {
      apiEvent("update-space-api", { response: "success" });
      return response;
    })
    .catch((error) => {
      apiEvent("update-space-api", { response: "error" });
      return {
        ok: false,
        status: 500,
      };
    });
};

export const getSpaceData = async (spaceId) => {
  return fetch(GET_SPACE_API_URL, {
    method: "post",
    body: JSON.stringify({
      spaceId,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return {
          ok: response.ok,
          status: response.status,
        };
      }
      return response.json();
    })
    .then((data) => {
      if (data && data.length === 0) {
        apiEvent("get-space-data", { response: "not_found" });
        return {
          ok: false,
          status: 404,
        };
      }
      apiEvent("get-space-data", { response: "success" });
      return {
        ok: true,
        status: 200,
        data: {
          spaceData: data[0].data,
          spaceInformation: data[0].space_information,
        },
      };
    })
    .catch((error) => {
      apiEvent("get-space-data", { response: "error" });
      return {
        ok: false,
        status: 500,
      };
    });
};

export const joinSpace = async (spaceDisplayId) => {
  return fetch(JOIN_SPACE_API_URL, {
    method: "post",
    body: JSON.stringify({
      spaceDisplayId,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .then((data) => {
      apiEvent("join-space", { response: "success" });
      return {
        ok: true,
        status: 200,
        data,
      };
    })
    .catch((error) => {
      apiEvent("join-space", { response: "error" });
      return {
        ok: false,
        status: 500,
      };
    });
};
