import { Table } from "flowbite-react";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { listOfInputValuesStore } from "../store/store";
import { copyTextToClipboard } from "../util/util";

export const TableView = ({ mode, handleDeleteClick }) => {
  const [listOfInputValues] = useAtom(listOfInputValuesStore);

  const [copiedIndex, setCopiedIndex] = useState(-1);

  const handleClickClipboard = (index) => {
    copyTextToClipboard(listOfInputValues.at(index).fieldValue).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(-1);
      }, 2000);
    });
  };

  return (
    <div className="overflow-x-auto flex w-full">
      <Table hoverable={true}>
        <Table.Head className="bg-gray-200">
          <Table.HeadCell>#</Table.HeadCell>
          <Table.HeadCell>Information Type</Table.HeadCell>
          <Table.HeadCell>Value</Table.HeadCell>
          <Table.HeadCell>
            {mode === "shared" ? "Copy" : "Delete"}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-x  text-md font-semibold text-gray-800">
          {listOfInputValues.map((inputValue, index) => {
            return (
              <Table.Row key={index} className="bg-gray-100">
                <Table.HeadCell>{index + 1}</Table.HeadCell>
                <Table.Cell>{inputValue.fieldKey}</Table.Cell>
                <Table.Cell>{inputValue.fieldValue}</Table.Cell>

                {mode === "shared" && (
                  <Table.Cell>
                    <button onClick={() => handleClickClipboard(index)}>
                      {" "}
                      {copiedIndex !== index && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.0}
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-900"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                          />
                        </svg>
                      )}
                      {copiedIndex === index && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.0}
                          stroke="currentColor"
                          className="w-6 h-6 text-green-800"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                          />
                        </svg>
                      )}
                    </button>
                  </Table.Cell>
                )}
                {mode === "delete" && (
                  <Table.Cell>
                    <button onClick={() => handleDeleteClick(index)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.0}
                        stroke="currentColor"
                        className="w-5 h-5  text-gray-900"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
