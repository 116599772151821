import { Outlet } from "react-router-dom";
import { FooterLayout } from "./Footer";
import { Navigation } from "./Navigation";

export const Layout = () => {
  return (
    <div className=" w-auto max-w-none min-h-screen flex flex-col font-Roboto">
      <Navigation />
      <div className="flex-grow pt-10">
        <Outlet />
      </div>
      <FooterLayout />
    </div>
  );
};
