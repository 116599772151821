const helperText = new Map([
  ["1", "One"],
  ["2", "Two"],
  ["3", "Three"],
  ["4", "Four"],
  ["5", "Five"],
  ["6", "Six"],
  ["7", "Seven"],
  ["8", "Eight"],
  ["9", "Nine"],
  ["0", "Zero"],
  ["A", "A like Adams"],
  ["B", "B like Boston"],
  ["C", "C like Chicago"],
  ["D", "D like Denver"],
  ["E", "E like Easy"],
  ["F", "F like Frank"],
  ["G", "G like George"],
  ["H", "H like Henry"],
  ["I", "I like Ida"],
  ["J", "J like John"],
  ["K", "K like King"],
  ["L", "L like Lincoln"],
  ["M", "M like Mary"],
  ["N", "N like New York"],
  ["O", "O like Ocean"],
  ["P", "P like Peter"],
  ["Q", "Q like Queen"],
  ["R", "R like Roger"],
  ["S", "S like Sugar"],
  ["T", "T like Thomas"],
  ["U", "U like Union"],
  ["V", "V like Victor"],
  ["W", "W like William"],
  ["X", "X like X-Ray"],
  ["Y", "Y like Young"],
  ["Z", "Z like Zebra"],
]);

export const helperTextForSpaceId = (spaceId) => {
  return spaceId
    .split("")
    .map((word) => {
      return helperText.get(word.toUpperCase());
    })
    .join(" - ");
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};
