export const Pages = {
  Landing: "Landing",
  NewSpace: "NewSpace",
  JoinSpace: "JoinSpace",
};

export const GAEventName = {
  CreateSpaceEvent: "create-space",
  JoinSpaceEvent: "join-space",
  AddDataEvent: "add-data",
};
