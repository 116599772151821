import { useAtom } from "jotai";

import { InformationCircleIcon } from "@heroicons/react/24/solid";

import { globalErrorStore, spaceInformationStore } from "../store/store";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  createSpaceClickedEvent,
  fatalErrorEvent,
  joinSpaceClickedEvent,
} from "../util/gaEvents";
import { Alert, Button, TextInput } from "flowbite-react";
import { createNewSpace, joinSpace } from "../api/space-api";
import { Pages } from "../types/constant";

export const LandingOptions = () => {
  const navigate = useNavigate();
  const [globalErrorState, setGlobalErrorState] = useAtom(globalErrorStore);
  const [isJoinFailed, setJoinFailed] = useState(false);
  const [errorState, setErrorState] = useState({
    error: false,
    errorTitle: "",
    errorMessage: "",
  });
  const [joinSpaceId, setJoinSpaceId] = useState("");
  const [, setSpaceInformation] = useAtom(spaceInformationStore);

  const handleJoinClick = async () => {
    const joinSpaceResponse = await joinSpace(joinSpaceId);
    if (joinSpaceResponse.ok) {
      const spaceId = joinSpaceResponse.data.spaceInformation.space_id;
      setSpaceInformation({ space_id: spaceId, space_display_id: joinSpaceId });
      navigate(`space/join/${spaceId}`);
    } else {
      fatalErrorEvent(Pages.Landing, {
        reason: "failed to join",
        response: "failed",
      });
      setJoinFailed(true);
    }
    joinSpaceClickedEvent();
  };

  const handleSpaceIdOnChangeEvent = (event) => {
    setJoinSpaceId(event.target.value);
  };

  useEffect(() => {
    if (globalErrorState.error) {
      setErrorState({ ...globalErrorState });
      setGlobalErrorState({
        error: false,
        errorMessage: "",
        errorTitle: "",
        errorType: "",
      });
      //send event ga for metrics
      fatalErrorEvent("LandingPage", {
        errorTitle: errorState.errorTitle,
        errorType: errorState.errorType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalErrorState]);

  const handleCreateSpaceClick = async () => {
    //clear session storage
    window.sessionStorage.removeItem("spaceId");
    window.sessionStorage.removeItem("spaceCreatedAt");
    const { apiStatus, spaceInformation } = await createNewSpace();
    if (apiStatus) {
      //Store the spaceId in the session
      window.sessionStorage.setItem("spaceId", spaceInformation.space_id);
      window.sessionStorage.setItem("spaceCreatedAt", Date.now());
      navigate("/space/new");
      createSpaceClickedEvent();
    } else {
      setGlobalErrorState({
        error: true,
        errorTitle: "Create Failed:",
        errorType: "failure",
        errorMessage: "Sorry, failed to create new space. Try again!",
      });
    }
  };

  return (
    <div className="flex items-center justify-center gap-5 flex-col">
      {errorState.error && (
        <div className="w-2/4">
          <Alert
            color={errorState.errorType}
            onDismiss={function onDismiss() {
              setErrorState({ error: false });
            }}
          >
            <span className="text-base flex align-middle">
              <InformationCircleIcon className="h-6 w-6 inline-block" />
              <span className="font-semibold px-1">
                {errorState.errorTitle}
              </span>
              {":  "}
              {errorState.errorMessage}
            </span>
          </Alert>
        </div>
      )}

      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="mx-auto max-w-screen-xl py-8 px-4 text-center lg:py-16 lg:px-12">
          <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
            Sharing Information Got Simpler!
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-700 dark:text-gray-400 sm:px-16 lg:text-xl xl:px-48">
            Spelling words over the phone can be difficult for several reasons.
            Now you can easily share your information with others by creating a
            <span className="text-purple-700 font-semibold"> New Space</span>{" "}
            and sharing the unique code. The other person can view the
            information by{" "}
            <span className="text-purple-700 font-semibold">
              Joining the Space{" "}
            </span>
            with unique code.
          </p>
          <div className="mb-8 flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 lg:mb-16">
            <Button color="purple" onClick={handleCreateSpaceClick}>
              New Space
            </Button>
            <div className="flex flex-row gap-1 justify-evenly">
              <div>
                <TextInput
                  placeholder="8 Character Code goes here"
                  value={joinSpaceId}
                  id="spaceId"
                  name="spaceId"
                  color={isJoinFailed ? "failure" : ""}
                  helperText={
                    isJoinFailed ? (
                      <React.Fragment>
                        <span className="font-medium">Oops!</span> Invalid space
                        id
                      </React.Fragment>
                    ) : (
                      ""
                    )
                  }
                  onChange={handleSpaceIdOnChangeEvent}
                  required={true}
                ></TextInput>
              </div>
              <Button color="dark" onClick={handleJoinClick}>
                Join Space
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
